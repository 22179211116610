<template>
    <div>
        <myHead/>
        <div style="margin-top:10px;margin-left: 20px;margin-right: 20px;margin-bottom: 20px;" >
            <a-table :loading="loading"  rowKey="Id"     :dataSource="list" :columns="columns" :pagination="false" >
                        <template #img="{ text }">
                            <a-image  style="width:100px;height:100px"  :src="text" />
                        </template>
                        <template #colTitle="{ text,record }">

                             <a target="_blank" :href="record.Url">{{text}}</a>
                        </template>
                        <template #aliTrademanager="{text}">
                            <a-tag color="#2db7f5" >{{text}}</a-tag>
                        </template>
                        <template #orderCode="{text,record,index}">
                            <a  @click="() => setListVisible(true,index)"><eye-outlined /> {{text}}</a>
                            <a-image
                            :width="5"
                            :style="{ display: 'none' }"
                            :preview="{
                                visible:record.ReviewImgVisible,
                                onVisibleChange: setListVisible,
                            }"
                            :src="record.ReviewImgList[0]"
                            />


                        </template>
                        <template #money="{text}">
                            <b>{{ text.toFixed(2) }}</b>
                        </template>
                        <template #status="{text}">
                            <a-tag v-if="text==1" color="#2db7f5">待处理</a-tag>
                            <a-tag v-else-if="text==2" color="#f50">已锁定</a-tag>
                            <a-tag v-else color="#87d068">已付款</a-tag>
                        </template>
                        <template #action="{ record,index }">
                            <a-button   @click="pay(record,index)">付款提交</a-button>
                            <a-image
                            :width="5"
                            :style="{ display: 'none' }"
                            :preview="{
                                visible:record.PayImgVisible,
                                onVisibleChange: setListPayVisible,
                            }"
                            :src="record.PayImgList[0]"
                            />
                        </template>
                    </a-table>
        </div>

        <a-drawer
            :width="500"
            title="付款提交"
            placement="right"
            :visible="drawerVisible"
            @close="onClose"
            :mask="false"
        >
            <p>    <a-tag color="blue">商品标题</a-tag></p>
            <p><a target="_blank" :href="setRow.Url">{{setRow.Title}}</a></p>
            <p><a-tag color="blue">实付款</a-tag></p>
            <p>
                <a-input-number v-model:value="money" prefix="￥" style="width: 100%" />
            </p>
            <p style="text-align: center;">
                <a-button :loading="operation" @click="paySumbit" type="primary">提 交</a-button>
            </p>
        </a-drawer>
    </div>
</template>
<style>
.ant-image-preview-mask{
    z-index:996
}
.ant-image-preview-wrap{
    z-index: 997;
}
.ant-drawer-content-wrapper{
    z-index: 999;
}
</style>
<script>
import {  ref } from 'vue';
import { orderGetOutsideCashierFunds,orderLock,orderCompletePay } from '@/request.js';
// import moment from 'moment';
import myHead from '../components/myHead'

// function formatDate(date) {
//     var y = date.getFullYear();
//     var m = date.getMonth() + 1;
//     m = m < 10 ? '0' + m : m;
//     var d = date.getDate();
//     d = d < 10 ? ('0' + d) : d;
//     return y + '-' + m + '-' + d;
// }


// import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import {  EyeOutlined} from '@ant-design/icons-vue';

export default {
    components: {
        myHead,EyeOutlined
    },
    setup(){
        const operation=ref(false);
        const money=ref(0);
        const setRow=ref({});
        const drawerVisible=ref(false);
        const loading=ref(false);
        const list=ref([]);
        const queryProcess=()=>{
            loading.value=true;
            orderGetOutsideCashierFunds({},(res)=>{
                list.value=res.Result;
                loading.value=false;
            })
        }
        let imgIndex=0;
        const onClose=()=>{
            setListPayVisible(false,imgIndex);
            drawerVisible.value=false;
        };
        const pay=(record,index)=>{
            setRow.value=record;
            money.value=setRow.value.Money;
            drawerVisible.value=true;
            setListPayVisible(true,index);
            orderLock({id:record.Id},(res)=>{
                if(res.IsSuccess){
                    record.Status=2;
                }
            })
        }
        const paySumbit=()=>{
            operation.value=true;
            orderCompletePay({id:setRow.value.Id,reviewImg:"",money:money.value},(res)=>{
                if(res.IsSuccess){
                    message.success("提交成功");
                    queryProcess();
                    onClose();
                }else{
                    message.error(res.Message);
                }
                operation.value=false;
            })
        }
        const setListPayVisible=(visible,index)=>{
            if(visible){
                if(typeof index === 'number'){
                    imgIndex=index;
                    list.value[imgIndex].PayImgVisible=visible;
                }
            }else{
                list.value[imgIndex].PayImgVisible=visible;
                drawerVisible.value=false;
            }
        };
        const setListVisible=(visible,index)=>{
            if(visible){
                if(typeof index === 'number'){
                    imgIndex=index;
                    list.value[imgIndex].ReviewImgVisible=visible;
                }
            }else{
                list.value[imgIndex].ReviewImgVisible=visible;
            }
        };

        queryProcess();
        return {
            operation,
            paySumbit,
            money,
            setRow,
            pay,
            onClose,
            drawerVisible,
            setListPayVisible,
            list,
            setListVisible,
            loading,
            queryProcess,
            columns:[
                {
                    title: '主图',
                    dataIndex: 'Img',
                    key: 'img',
                    slots: { customRender: 'img' },
                },
                {
                    title: '计划编号',
                    dataIndex: 'Code',
                    key: 'code',
                },
                {
                    title: '店铺名称',
                    dataIndex: 'ShopName',
                    key: 'shopName',
                },
                {
                    title: '标题',
                    dataIndex: 'Title',
                    key: 'title',
                    slots: { customRender: 'colTitle' },
                },
                {
                    title: '提交单号',
                    dataIndex: 'OrderCode',
                    key: 'orderCode',
                    slots: { customRender: 'orderCode' },
                },
                {
                    title: '下单账号',
                    dataIndex: 'AliTrademanager',
                    key: 'aliTrademanager',
                    slots: { customRender: 'aliTrademanager' },
                },
                {
                    title: '实付金额',
                    dataIndex: 'Money',
                    key: 'money',
                    slots: { customRender: 'money' },
                },
                {
                    title: '付款状态',
                    dataIndex: 'Status',
                    key: 'status',
                    slots: { customRender: 'status' },
                },
                {
                    title: '下单时间',
                    dataIndex: 'CreateTime',
                    key: 'createTime',
                },
                {
                    title: '操作',
                    slots: { customRender: 'action' },
                },
            ]
        };
    }
}
</script>
